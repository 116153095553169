

import VueRouter from "vue-router";
import config from "../common/config"

const router = new VueRouter({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { keepAlive: true },
      component: () => import('../components/home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../components/register.vue')
    },
    {
      path: '/video',
      name: 'video',
      component: () => import('../components/video.vue')
    },
    {
      path: '/usercenter',
      name: 'usercenter',
      component: () => import('../components/usercenter.vue')
    },
    {
      path: '/revise',
      name: 'revise',
      component: () => import('../components/revise.vue')
    },
    {
      path: '/empty',
      name: 'empty',
      component: () => import('../components/empty.vue')
    },
    {
      path: '/record',
      name: 'record',
      component: () => import('../components/record.vue')
    },
    {
      path: '/recharge',
      name: 'recharge',
      component: () => import('../components/recharge.vue')
    },
    {
      path: '/exchange',
      name: 'exchange',
      component: () => import('../components/exchange.vue')
    },
    {
      path: '/cash',
      name: 'cash',
      component: () => import('../components/cash.vue')
    },
    {
      path: '/edit',
      name: 'edit',
      component: () => import('../components/edit.vue')
    },
    {
      path: '/rebate',
      name: 'rebate',
      component: () => import('../components/rebate.vue')
    }
  ]
});
router.beforeEach((to, from, next) => {
  // 在路由变化之前执行的逻辑
  const currentRoute = to.path;
  config.previousRoutePath = from.path;
  // 调用 Android 原生 JavaScript 接口并传递当前路由
 // window.android.onVueRouteChange(currentRoute);

 next(); // 继续路由导航
});

export default router;
