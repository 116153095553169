const config = {
    'app_host':'',
   //'app_host':'http://127.0.0.5',
    'appid':'10000',
    'appkey':'3f61451c2a8fc748444aaa00543ffca8',
    'secret_key':'xing1995921',
    'edition':'1.0',
    'notice':false,

    'appinfo':'',
    'apiinfo':'',
    'extend':'',

    'logininfo':'',
    'token':'',
    'state':false,

    'pay':'',
    'payinfo':'',
    
    'contact':'',

    'shareinfo':'',
    'exchangeinfo':'',
    'cashinfo':'',

    'inv':'', //邀请人

    'previousRoutePath': ''




}
export default config