import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from '../src/router'

import './utils/appback'

import Vant from 'vant';
import 'vant/lib/index.css';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify)

Vue.use(Vant);

Vue.use(VueRouter)

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  router:router

}).$mount('#app')






